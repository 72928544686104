<template>
  <div>
    <div style="margin: 0 0 16px 8px">
      <a-breadcrumb separator="»">
        <a-breadcrumb-item>
          <span style="font-size: 16px;">
            <b>Quản lý ngày công</b>
          </span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a-icon type="table" />
          <span>Chấm công</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link to="/admin/thong-ke-cham-cong.html">
            <a-icon type="table" />
            <span>Thống kê</span>
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link to="/admin/cong-nhat/tong-cong.html">
            <a-icon type="table" style="color: #1890ff" />
            <span style="color: #1890ff">Tổng công</span>
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link to="/admin/bao-cao/bieu-do-tong-cong.html">
            <a-icon type="line-chart" />
            <span>Biểu đồ lượng khách</span>
          </router-link>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <a-card>
      <a-form layout="inline">
        <a-row :gutter="8">
          <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <a-range-picker
              @change="onChangeDate"
              :format="'DD/MM/YYYY'"
              :defaultValue="[moment().startOf('month'), moment()]"
              style="width: 100%"
            />
          </a-col>
          <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <a-select
              placeholder="Chọn tất cả salon"
              default-value="0"
              v-model="queryParam.salonId"
              :filterOption="filterOptionSalon"
              showSearch
            >
              <a-select-option value="0">Chọn tất cả salon</a-select-option>
              <a-select-option v-for="salon in user.listSalon" :key="salon.id">{{ salon.salonName }}</a-select-option>
            </a-select>
          </a-col>
          <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <a-select placeholder="Chọn tất cả" default-value="0" v-model="queryParam.staffType">
              <a-select-option value="0">Chọn tất cả</a-select-option>
              <a-select-option
                v-for="staffType in staffTypeOptions"
                :key="staffType.value"
              >{{ staffType.text }}</a-select-option>
            </a-select>
          </a-col>
          <a-col :xs="24" :sm="4" :md="6" :lg="16" :xl="6">
            <a-button type="primary" @click="getData">Xem danh sách</a-button>
            <a-button type="default" style="margin-left: 10px" @click="resetFilter">Reset Filter</a-button>
          </a-col>
        </a-row>
      </a-form>
      <div style="margin-top: 30px">
        <p>
          <span>
            <a-icon type="ordered-list" style="margin-right: 10px" />
            <strong>Danh sách nhân viên</strong>
          </span>
        </p>
        <a-table
          :columns="columns"
          :dataSource="dataSource"
          :loading="loading"
          :bordered="true"
          :rowKey="record => record.staffId"
          :pagination="false"
        >
          <span slot="no" slot-scope="text, record, index">{{ getNo(index) }}</span>
          <a-button slot="action" slot-scope="text, record" @click="viewDetail(record)">Xem chi tiết</a-button>
        </a-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParam.page"
          :limit.sync="queryParam.limit"
          @pagination="getData"
        />
      </div>
    </a-card>
    <timekeeping-detail-dialog v-if="visible" :visible.sync="visible" :data="staffInfo"></timekeeping-detail-dialog>
  </div>
</template>

<script>
import { getFactory } from '@/api'
import { mapGetters } from 'vuex'
import moment from 'moment'
import Pagination from '@/components/Pagination'
import TimekeepingDetailDialog from './components/TimekeepingDetailDialog'
const TimekeepingReportRepository = getFactory('timekeepingReport')
const StaffTypeRepository = getFactory('staffType')

export default {
  computed: {
    ...mapGetters(['user'])
  },
  components: { Pagination, TimekeepingDetailDialog },
  created() {
    this.getStaffType()
  },
  data() {
    return {
      workDate: moment(),
      queryParam: {
        fromDate: moment()
          .startOf('month')
          .format('DD/MM/YYYY'),
        toDate: moment().format('DD/MM/YYYY'),
        salonId: '0',
        limit: 10,
        page: 1
      },
      dataSource: [],
      loading: false,
      total: 0,
      staffTypeOptions: [],
      columns: [
        {
          title: 'STT',
          scopedSlots: { customRender: 'no' }
        },
        {
          title: 'ID',
          dataIndex: 'staffId'
        },
        {
          title: `Nhân viên`,
          dataIndex: 'staffName'
        },
        {
          title: `Loại nhân viên`,
          dataIndex: 'departmentName'
        },
        {
          title: `Tổng công`,
          dataIndex: 'workDay'
        },
        {
          title: `Tổng giờ làm part-time (làm tăng ca)`,
          dataIndex: 'workHour'
        },
        {
          title: `Chi tiết`,
          scopedSlots: { customRender: 'action' }
        }
      ],
      visible: false,
      staffInfo: {}
    }
  },
  watch: {},
  methods: {
    moment,
    async getStaffType() {
      try {
        this.staffTypeOptions = await StaffTypeRepository.getCustomPath('selected-value')
      } catch (error) {
        console.log(error)
      }
    },
    async getData() {
      try {
        this.queryParam.page = 1
        this.dataSource = []
        if (!this.queryParam.fromDate || !this.queryParam.toDate) {
          this.$message.error('Vui lòng chọn khoảng thời gian để xem dữ liệu.')
          return
        }
        this.loading = true
        this.total = 0
        const res = await TimekeepingReportRepository.getCustomPath('staffs', this.queryParam)
        this.dataSource = res.results
        this.total = res.total
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    resetFilter() {
      this.queryParam.salonId = '0'
      this.queryParam.staffType = '0'
    },
    onChangeDate(date, dateString) {
      if (date && date.length > 1) {
        if (dateString[0] !== '' && dateString[1] !== '') {
          this.queryParam.fromDate = date[0].format('DD/MM/YYYY')
          this.queryParam.toDate = date[1].format('DD/MM/YYYY')
        } else {
          this.queryParam.fromDate = undefined
          this.queryParam.toDate = undefined
          this.dataSource = []
          this.toDate = 0
        }
      } else {
        this.queryParam.fromDate = undefined
        this.queryParam.toDate = undefined
        this.dataSource = []
        this.total = 0
      }
    },
    getNo(index) {
      return this.queryParam.limit * (this.queryParam.page - 1) + (index + 1)
    },
    viewDetail(record) {
      let salons = [...this.user.listSalon]
      salons.push({ id: '0', salonName: 'Chọn tất cả salon' })
      let salon = salons.find(x => x.id == this.queryParam.salonId)
      console.log(salon)
      this.staffInfo = Object.assign(
        {
          staffId: record.staffId,
          staffName: record.staffName,
          departmentName: record.departmentName,
          salonName: salon.salonName
        },
        this.queryParam
      )
      this.visible = true
    },
    filterOptionSalon(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }
}
</script>

<template>
  <a-modal
    :visible="localVisible"
    style="top: 20px;"
    :width="window.width > 1024 ? 800 : window.width - 100"
    @cancel="handleCancel"
    :loading="loading"
    bordered
  >
    <template slot="title">
      <p>
        Tên nhân viên : <span>{{ data.staffName }}</span> Bộ phận &nbsp; : &nbsp;
        <span>{{ data.departmentName }}</span> &nbsp; &nbsp; | &nbsp; &nbsp; Salon &nbsp;:&nbsp;
        <span>{{ data.salonName }}</span>
      </p>
      <p>
        Từ ngày : &nbsp; <span>{{ data.fromDate }}</span
        >&nbsp; đến ngày :&nbsp; <span>{{ data.toDate }}</span>
      </p>
    </template>
    <template slot="footer">
      <a-button key="back" @click="handleCancel">Đóng</a-button>
    </template>
    <a-table
      :columns="columns"
      :dataSource="dataSource"
      :loading="loading"
      :rowKey="(record, index) => index"
      :pagination="false"
      :scroll="{ y: window.height > 768 ? window.height - 400 : window.height - 100 }"
      size="small"
    >
      <span slot="no" slot-scope="text, record, index">{{ getNo(index) }}</span>
      <span slot="workDate" slot-scope="text, record">{{ moment(record.workDate).format('DD/MM/YYYY') }}</span>
    </a-table>
  </a-modal>
</template>

<script>
const columns = [
  {
    title: 'STT',
    scopedSlots: { customRender: 'no' },
    width: 50
  },
  {
    title: 'Ngày chấm công',
    dataIndex: 'workDate',
    scopedSlots: { customRender: 'workDate' },
    width: 150
  },
  {
    title: `Salon được chấm công`,
    dataIndex: 'shortName',
    width: 200
  },
  {
    title: `Ca làm việc`,
    dataIndex: 'w_Name',
    width: 100
  },
  {
    title: `Giờ parttime`,
    dataIndex: 'workHour'
  }
]
import moment from 'moment'
import { getFactory } from '@/api'
const TimekeepingReportRepository = getFactory('timekeepingReport')
export default {
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  async created() {
    console.log('this.data', this.data)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    await this.getData()
  },
  data() {
    return {
      columns,
      dataSource: [],
      loading: false,
      window: {
        width: 0
      }
    }
  },
  methods: {
    moment,
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    async getData() {
      try {
        this.loading = true
        this.dataSource = []
        const query = {
          fromDate: this.data.fromDate,
          toDate: this.data.toDate
        }
        this.dataSource = await TimekeepingReportRepository.getCustomPath(`/staffs/${this.data.staffId}`, query)
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    handleCancel() {
      this.localVisible = false
    },
    getNo(index) {
      return index + 1
    }
  }
}
</script>
<style scoped>
.ant-modal-title {
  font-weight: normal !important;
}

.ant-modal-title p {
  margin: 0;
}
.ant-modal-title p span {
  font-size: 16px;
  line-height: 25px;
  font-weight: bold;
}
</style>
